import { For, Show } from "solid-js";

import type { TextWithFormatField } from "~/types/drupal_jsonapi";

import "./ContentSEOText.css";

export default function ContentSeoText(props: {
  title?: string;
  bodies: TextWithFormatField[];
}) {
  return (
    <>
      <div class="content-seo-text" data-test="seo-block">
        <div class="content-part">
          <Show when={props.title}>
            <h1>{props.title}</h1>
          </Show>
          <For each={props.bodies}>
            {(body) => {
              return (
                <div class="block">
                  <div class="text" innerHTML={body.value} />
                </div>
              );
            }}
          </For>
        </div>
      </div>
    </>
  );
}
